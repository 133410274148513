body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
}

.content {
  padding: 20px;
}

h2 {
  color: #333;
}
