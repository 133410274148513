.header {
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 40px;
}

.header-title {
  font-size: 3em;
  margin: 0;
}

.header-subtitle {
  font-size: 1.2em;
  color: #61dafb;
}

.header-nav {
  margin-top: 20px;
}

.header-nav a {
  color: #61dafb;
  margin: 0 15px;
  text-decoration: none;
  font-size: 1em;
}

.header-nav a:hover {
  text-decoration: underline;
}
