.experience {
    padding: 40px;
    background-color: #ffffff;
  }
  
  .experience h2 {
    text-align: center;
    color: #333;
  }
  
  .experience-item {
    max-width: 800px;
    margin: 20px auto;
    text-align: left;
  }
  
  .experience-duration {
    font-style: italic;
    color: #777;
  }
  
  .experience-item ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .experience-item li {
    color: #555;
    margin: 10px 0;
  }
  