.skills {
    padding: 40px;
    background-color: #f1f1f1;
  }
  
  .skills h2 {
    text-align: center;
    color: #333;
  }
  
  .skills-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
    list-style-type: none;
    padding: 0;
    justify-content: center;
  }
  
  .skills-list li {
    background-color: #3498db;
    color: white;
    padding: 10px 15px;
    margin: 5px;
    border-radius: 8px;
  }
  